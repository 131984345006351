<script>
import { PortalTarget } from 'portal-vue';

export default {
  name: 'AlertsApp',
  components: {
    PortalTarget,
  },
};
</script>

<template><portal-target name="alerts" multiple /></template>
