<script>
import { MountingPortal } from 'portal-vue';
import { throttle } from 'lodash-es';
import * as Sentry from '@sentry/browser';
import { NAV_TRACKING_LABELS } from '~/javascripts/tracking/constants';
import getBillingAccountQuery from '~/javascripts/billing_account/graphql/queries/get_billing_account.query.graphql';
import SidebarHeaderApp from './sidebar_header_app.vue';
import SidebarItem from './sidebar_item.vue';
import SidebarToggleButton from './sidebar_toggle_button.vue';

export default {
  name: 'SidebarApp',
  components: { MountingPortal, SidebarHeaderApp, SidebarToggleButton, SidebarItem },
  inject: ['zuoraAccountNumber'],
  data() {
    return {
      billingAccount: {},
      isExpanded: false,
    };
  },
  apollo: {
    billingAccount: {
      query: getBillingAccountQuery,
      variables() {
        return {
          zuoraAccountNumber: this.zuoraAccountNumber,
        };
      },
      skip() {
        return !this.zuoraAccountNumber;
      },
      update(data) {
        return data.billingAccount || {};
      },
      error(error) {
        this.handleError(error);
      },
    },
  },
  computed: {
    sidebarClasses() {
      return {
        sidebar: true,
        open: this.isExpanded,
      };
    },
  },
  created() {
    const { pathname } = window.location;
    const items = [
      {
        title: 'Subscriptions & purchases',
        isActive: pathname === '/' || /\/subscriptions\/?$/.test(pathname),
        path: '/',
        trackingLabel: NAV_TRACKING_LABELS.subscription,
      },
      {
        title: 'Invoices',
        isActive:
          /\/invoices\/?$/.test(pathname) || pathname.startsWith('/billing/invoice_payments/new'),
        path: '/invoices',
        trackingLabel: NAV_TRACKING_LABELS.invoices,
      },
      {
        title: 'Billing account settings',
        isActive: /\/billing_accounts\/?$/.test(pathname),
        path: '/billing_accounts',
        trackingLabel: NAV_TRACKING_LABELS.billingSettings,
      },
    ];

    this.items = items;
    this.hasActiveMenuItem = items.some((item) => item.isActive);
  },
  mounted() {
    this.resizeThrottled = throttle(this.handlePageResize, 400);
    window.addEventListener('resize', this.resizeThrottled);
    // Uses CustomEvents on window to send message to another Vue app
    // Should be migrated to in-app communication with: https://gitlab.com/gitlab-org/customers-gitlab-com/-/issues/7227
    window.addEventListener('sidebar-toggle', this.handleSidebarToggle);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeThrottled);
    window.removeEventListener('sidebar-toggle', this.handleSidebarToggle);
  },
  methods: {
    getProps(item) {
      const { isActive, path, trackingLabel } = item;

      return { isActive, path, trackingLabel };
    },
    handlePageResize() {
      this.isExpanded = false;
    },
    handleSidebarToggle() {
      this.isExpanded = !this.isExpanded;
    },
    handleError(error) {
      Sentry.captureException(error);
    },
  },
};
</script>

<template>
  <aside v-if="hasActiveMenuItem" :class="sidebarClasses">
    <mounting-portal mount-to="#js-sidebar-header" slim>
      <sidebar-header-app />
    </mounting-portal>
    <sidebar-toggle-button
      class="-gl-ml-3 gl-mt-4"
      button-text="Hide sidebar"
      button-icon="collapse-left"
    />
    <nav aria-labelledby="secondary-navigation">
      <label id="secondary-navigation" class="gl-h3 gl-pb-2" data-testid="secondary-nav-label">{{
        billingAccount.zuoraAccountName
      }}</label>
      <p class="gl-pb-5 gl-text-sm gl-leading-normal gl-text-gray-700">
        Account ID: {{ zuoraAccountNumber }}
      </p>
      <ol class="gl-list-none gl-p-0">
        <sidebar-item v-for="item in items" :key="item.title" v-bind="getProps(item)"
          >{{ item.title }}
        </sidebar-item>
      </ol>
    </nav>
  </aside>
</template>
