import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { apolloClient } from '~/javascripts/lib/graphql';
import AlertsApp from './components/alerts_app.vue';

Vue.use(VueApollo);

export default () => {
  const el = document.getElementById('js-alerts');

  if (!el) {
    return false;
  }

  const apolloProvider = new VueApollo({ defaultClient: apolloClient });

  return new Vue({
    el,
    apolloProvider,
    render: (createElement) => createElement(AlertsApp),
  });
};
